'use strict';

import '../scss/user.scss';
//import { theme } from './theme.js';

/* $(document).on('ready', function($) {
  theme();
}); */

document.addEventListener('DOMContentLoaded', function() {
  /* var navbar = document.querySelector(".navbar");
  if (navbar == null) return;
  const navOffCanvasBtn = document.querySelectorAll(".offcanvas-nav-btn");
  const navOffCanvasBtnClose = document.querySelectorAll('.offcanvas-nav-btn-close');
  const navOffCanvas = document.querySelector('.navbar:not(.navbar-clone) .offcanvas-nav');
  //const bsOffCanvas = new bootstrap.Offcanvas(navOffCanvas, {scroll: true});
  const scrollLink = document.querySelectorAll('.onepage .navbar li a.scroll');
  const searchOffcanvas = document.getElementById('offcanvas-search');
  navOffCanvasBtn.forEach(e => {
    e.addEventListener('click', event => {
      navOffCanvas.style.display = "flex";
      navOffCanvas.style.visibility = "visible";
      navOffCanvas.style.transform = "translateX(0)";
    })
  });
  navOffCanvasBtnClose.forEach(e => {
    e.addEventListener('click', event => {
      console.log('test');
      navOffCanvas.style.visibility = "hidden";
      navOffCanvas.style.transform = "translateX(100%)";
    })
  });
  scrollLink.forEach(e => {
    e.addEventListener('click', event => {
      
    })
  });
  if(searchOffcanvas != null) {
    searchOffcanvas.addEventListener('shown.bs.offcanvas', function () {
      document.getElementById("search-form").focus();
    });
  } */
});